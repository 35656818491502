import { useState } from "react";

export type AwaitableComponentStatus =
  | "idle"
  | "awaiting"
  | "resolved"
  | "rejected";

type AwaitableComponentData<T = string> = {
  status: AwaitableComponentStatus;
  resolve: ((value: T) => void) | null;
  reject: ((reason?: string) => void) | null;
};

export default function useAwaitableComponent<T = string>() {
  const [data, setData] = useState<AwaitableComponentData<T>>({
    status: "idle",
    resolve: null,
    reject: null,
  });

  const handleResolve = (val: T) => {
    if (data.status !== "awaiting") {
      throw "Awaitable component is not awaiting.";
    }

    data.resolve?.(val);
    setData({ status: "resolved", resolve: null, reject: null });
  };

  const handleReject = (err?: string) => {
    if (data.status !== "awaiting") {
      throw "Awaitable component is not awaiting.";
    }

    data.reject?.(err);
    setData({ status: "rejected", resolve: null, reject: null });
  };

  const handleReset = () => {
    setData({ status: "idle", resolve: null, reject: null });
  };

  const handleExecute = async () => {
    return new Promise<T>((resolve, reject) => {
      setData({ status: "awaiting", resolve, reject });
    });
  };

  return [
    data.status,
    handleExecute,
    handleResolve,
    handleReject,
    handleReset,
  ] as const;
}
