import type React from "react";

import Modal from "~/components/Modal";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: (error?: string) => void;
  onConfirm: (isConfirmed: boolean) => void;
  title?: string;
  description?: React.ReactNode | string;
  cancelText?: string;
  confirmText?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  cancelText = "Cancel",
  confirmText = "Confirm",
  title = "Confirm Action",
  description = "Are you sure you want to proceed with this action?",
}) => {
  return (
    <Modal title={title} isOpen={isOpen} setIsOpen={() => onClose()}>
      <div className="border-b border-white/10 p-8 text-xl text-white/60">
        {description}
      </div>

      <footer className="p-8">
        <button
          type="button"
          onClick={() => onConfirm(true)}
          className="mb-4 w-full rounded-xl bg-white px-5 py-5 text-xl font-bold uppercase text-black transition hover:bg-white/80 disabled:cursor-not-allowed disabled:opacity-60"
        >
          {confirmText}
        </button>

        <button
          type="button"
          onClick={() => onClose()}
          className="w-full px-5 py-2 text-lg font-bold uppercase text-white/60 transition hover:text-white disabled:cursor-not-allowed"
        >
          {cancelText}
        </button>
      </footer>
    </Modal>
  );
};

export default ConfirmationModal;
