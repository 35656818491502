import { type MessageReportCategory } from "@hytopia.com/lib/dist/social/chat/reportMessage";
import { type MessageDto } from "@hytopia.com/lib/dist/social/chat/retrieveMessages";
import { type FriendshipDto } from "@hytopia.com/lib/dist/social/friends/getFriendships";
import { useFetcher, useLocation, useRouteLoaderData } from "@remix-run/react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import toast from "react-hot-toast/headless";

import ConfirmationModal from "~/components/modals/ConfirmationModal";
import useAwaitableComponent from "~/hooks/useAwaitableComponent";
import { type loader as rootLoader } from "~/root";
import { type action as refreshFriendAction } from "~/routes/friends+/refresh";
import { hytopiaClient } from "~/utils/hytopiaClient";
import { startViewTransition } from "~/utils/transitions.client";

const FriendsSidebarContext = createContext<{
  isVisible: boolean;
  isChatOpen: boolean;
  isAddingFriend: boolean;
  selectedChatFriend?: FriendshipDto;
  unreadMessageCount: Map<string, number>;
  clearChatMessages: (friendShipId: string) => void;
  markMessagesAsRead: (friendshipId: string) => void;
  setIsVisibleWithTransition: (value: boolean) => void;
  reportMessage: (
    messageId: string,
    category: MessageReportCategory,
  ) => Promise<boolean>;
  joinLobby: (lobbyId: string) => void;
  spectateLobby: (lobbyId: string) => void;
  blockFriend: (friendId: string) => void;
  unblockFriend: (friendId: string) => void;
  favoriteFriend: (friendId: string) => void;
  unfavoriteFriend: (friendId: string) => void;
  unfriendFriend: (friend: FriendshipDto["friend"]) => void;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddingFriend: React.Dispatch<React.SetStateAction<boolean>>;
  openAddFriendModal: () => void;
  getChatMessages: (fromUserId: string) => Promise<MessageDto[] | undefined>;
  setSelectedChatFriend: React.Dispatch<
    React.SetStateAction<FriendshipDto | undefined>
  >;
} | null>(null);

export function FriendsSidebarProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isAddingFriend, setIsAddingFriend] = useState(false);
  const fetcher = useFetcher<typeof refreshFriendAction>();
  const [status, execute, resolve, reject, reset] =
    useAwaitableComponent<boolean>();
  const [selectedUnfriendFriend, setSelectedUnfriendFriend] =
    useState<FriendshipDto["friend"]>();
  const [selectedChatFriend, setSelectedChatFriend] = useState<FriendshipDto>();
  const rootLoaderData = useRouteLoaderData<typeof rootLoader>("root");
  const [chatMessagesByFriendshipId, setChatMessagesByFriendshipId] = useState<
    Map<string, MessageDto[]>
  >(new Map());
  const [unreadMessageCount, setUnreadMessageCount] = useState<
    Map<string, number>
  >(new Map());

  const setIsVisibleWithTransition = (value: boolean) => {
    startViewTransition(() => setIsVisible(value));
  };

  const reportMessage = async (
    messageId: string,
    category: MessageReportCategory,
  ) => {
    if (!rootLoaderData?.currentUser?.accessToken) {
      return false;
    }

    try {
      const response = await hytopiaClient.social.chat.reportMessage({
        messageId,
        category,
        authToken: rootLoaderData.currentUser.accessToken,
      });

      if (response.error) {
        return false;
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const favoriteFriend = async (friendId: string) => {
    if (!rootLoaderData?.currentUser?.accessToken) {
      return;
    }

    try {
      const response = await hytopiaClient.social.friends.favorite(
        rootLoaderData.currentUser.accessToken,
        friendId,
      );

      if (response.error) {
        throw new Error(response.error.message);
      }

      fetcher.submit(null, { method: "POST", action: "/friends/refresh" });
    } catch (error) {
      toast.error("Something went wrong while favoriting a friend.");
    }
  };

  const unfavoriteFriend = async (friendId: string) => {
    if (!rootLoaderData?.currentUser?.accessToken) {
      return;
    }

    try {
      const response = await hytopiaClient.social.friends.unfavorite(
        rootLoaderData.currentUser.accessToken,
        friendId,
      );

      if (response.error) {
        throw new Error(response.error.message);
      }

      fetcher.submit(null, { method: "POST", action: "/friends/refresh" });
    } catch (error) {
      toast.error("Something went wrong while unfavoriting a friend.");
    }
  };

  const blockFriend = async (friendId: string) => {
    if (!rootLoaderData?.currentUser?.accessToken) {
      return;
    }

    try {
      const response = await hytopiaClient.social.users.block(
        rootLoaderData.currentUser.accessToken,
        friendId,
      );

      if (response.error) {
        throw new Error(response.error.message);
      }

      fetcher.submit(null, { method: "POST", action: "/friends/refresh" });
    } catch (error) {
      toast.error("Something went wrong while blocking a friend.");
    }
  };

  const unblockFriend = async (friendId: string) => {
    if (!rootLoaderData?.currentUser?.accessToken) {
      return;
    }

    try {
      const response = await hytopiaClient.social.users.unblock(
        rootLoaderData.currentUser.accessToken,
        friendId,
      );

      if (response.error) {
        throw new Error(response.error.message);
      }

      fetcher.submit(null, { method: "POST", action: "/friends/refresh" });
    } catch (error) {
      toast.error("Something went wrong while unblocking a friend.");
    }
  };

  const joinLobby = async (lobbyId: string) => {
    if (!rootLoaderData?.currentUser?.accessToken) {
      return;
    }

    try {
      const lobby = await hytopiaClient.play.matchmaking.lobbies.join({
        lobbyId,
        authToken: rootLoaderData.currentUser.accessToken,
      });

      if (lobby.error) {
        throw new Error(lobby.error.message);
      }

      const params = new URLSearchParams({
        join: lobby.url.replace("https://", ""),
        sessionToken: lobby.sessionToken,
        lobbyId: lobby.id,
      });

      window.open(`https://play.hytopia.com?${params.toString()}`, "_blank");
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const spectateLobby = async (lobbyId: string) => {
    if (!rootLoaderData?.currentUser?.accessToken) {
      return;
    }

    try {
      const lobby = await hytopiaClient.play.matchmaking.lobbies.spectate({
        lobbyId,
        authToken: rootLoaderData.currentUser.accessToken,
      });

      if (lobby.error) {
        throw new Error(lobby.error.message);
      }

      const params = new URLSearchParams({
        join: lobby.url.replace("https://", ""),
        sessionToken: lobby.sessionToken,
        lobbyId: lobby.id,
      });

      window.open(`https://play.hytopia.com?${params.toString()}`, "_blank");
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const unfriendFriend = async (friend: FriendshipDto["friend"]) => {
    if (!rootLoaderData?.currentUser?.accessToken) {
      return;
    }

    setSelectedUnfriendFriend(friend);

    try {
      const confirmed = await execute();

      if (!confirmed) {
        return;
      }

      const response = await hytopiaClient.social.friends.unfriend(
        rootLoaderData.currentUser.accessToken,
        friend.id,
      );

      if (response.error) {
        throw new Error(response.error.message);
      }

      fetcher.submit(null, { method: "POST", action: "/friends/refresh" });
    } catch (error) {
      if (!error) {
        return;
      }

      toast.error("Something went wrong while unfriending a friend.");
    } finally {
      reset();
      setTimeout(() => setSelectedUnfriendFriend(undefined), 100);
    }
  };

  const getChatMessages = useCallback(
    async (friendshipId: string) => {
      if (!rootLoaderData?.currentUser?.accessToken) {
        return;
      }

      if (chatMessagesByFriendshipId.has(friendshipId)) {
        return chatMessagesByFriendshipId.get(friendshipId);
      }

      try {
        const response = await hytopiaClient.social.chat.retrieveMessages(
          rootLoaderData.currentUser.accessToken,
          friendshipId,
        );

        if (response.error) {
          throw new Error(response.error.message);
        }

        const reversedMessages = response.messages.reverse();

        setChatMessagesByFriendshipId((prev) =>
          new Map(prev).set(friendshipId, reversedMessages),
        );

        return reversedMessages;
      } catch (error) {
        toast.error("Something went wrong while fetching messages.");
      }
    },
    [rootLoaderData?.currentUser?.accessToken, chatMessagesByFriendshipId],
  );

  const markMessagesAsRead = useCallback((friendshipId: string) => {
    setUnreadMessageCount((prev) => {
      const newCount = new Map(prev);

      newCount.set(friendshipId, 0);

      return newCount;
    });
  }, []);

  const clearChatMessages = useCallback((friendShipId: string) => {
    setChatMessagesByFriendshipId((prev) => {
      const newMessages = new Map(prev);
      newMessages.set(friendShipId, []);

      return newMessages;
    });
  }, []);

  const openAddFriendModal = useCallback(() => {
    startViewTransition(() => {
      setIsVisible(true);
      setIsAddingFriend(true);
    });
  }, []);

  useEffect(() => {
    if (!rootLoaderData?.currentUser) {
      return;
    }

    const socket = hytopiaClient.social.createAuthenticatedSocket(
      rootLoaderData.currentUser.accessToken,
    );

    function revalidateFriends() {
      fetcher.submit(null, { method: "POST", action: "/friends/refresh" });
    }

    function refreshUserSession(profilePictureURL?: string) {
      const formData = new FormData();

      formData.append("redirectTo", location.pathname);

      if (profilePictureURL) {
        formData.append("profilePictureURL", profilePictureURL);
      }

      fetcher.submit(formData, { method: "POST", action: "/auth/refresh" });
    }

    socket.on("friendRequestReceived", (friendRequest) => {
      toast.success(
        `You have a friend request from ${friendRequest.fromUser.username}.`,
      );
      revalidateFriends();
    });

    socket.on("friendRequestUpdated", (friendRequest) => {
      if (friendRequest.friendship) {
        toast.success(
          `${friendRequest.friendship.friend.username} accepted your friend request.`,
        );
      }
      revalidateFriends();
    });

    socket.on("chatMessageReceived", (message) => {
      setChatMessagesByFriendshipId((prev) => {
        const newMessages = new Map(prev);
        const messages = newMessages.get(message.friendshipId) || [];

        if (messages.some((m) => m.id === message.id)) {
          return newMessages;
        }

        messages.push({
          id: message.id,
          message: message.message,
          senderId: message.sender.id,
          timestamp: new Date(message.timestamp),
        });

        newMessages.set(message.id, messages);

        return newMessages;
      });

      setUnreadMessageCount((prev) => {
        if (message.sender.id === rootLoaderData.currentUser?.id) {
          return prev;
        }

        const newCount = new Map(prev);
        const currentCount = newCount.get(message.friendshipId) || 0;

        newCount.set(message.friendshipId, currentCount + 1);

        return newCount;
      });
    });

    socket.on("userUpdated", (user) => {
      if (user.id === rootLoaderData.currentUser?.id) {
        refreshUserSession(user.profilePictureURL);
        return;
      }

      revalidateFriends();
    });
    socket.on("friendshipEnded", revalidateFriends);
    // socket.on("userConnected", revalidateFriends);
    // socket.on("userDisconnected", revalidateFriends);
    socket.on("userJoinedLobby", revalidateFriends);
    socket.on("userLeftLobby", revalidateFriends);

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootLoaderData?.currentUser]);

  return (
    <FriendsSidebarContext.Provider
      value={{
        isVisible,
        joinLobby,
        isChatOpen,
        blockFriend,
        unblockFriend,
        unfriendFriend,
        setIsVisible,
        setIsChatOpen,
        reportMessage,
        spectateLobby,
        favoriteFriend,
        unfavoriteFriend,
        getChatMessages,
        clearChatMessages,
        markMessagesAsRead,
        unreadMessageCount,
        selectedChatFriend,
        setSelectedChatFriend,
        setIsVisibleWithTransition,
        isAddingFriend,
        setIsAddingFriend,
        openAddFriendModal,
      }}
    >
      {children}

      <ConfirmationModal
        onClose={reject}
        onConfirm={resolve}
        title={`Unfriend ${selectedUnfriendFriend?.username}`}
        confirmText="Yes, Unfriend"
        description={
          <>
            Are you sure you want to unfriend{" "}
            <strong className="text-white">
              {selectedUnfriendFriend?.username}
            </strong>
            ?
            <br />
            You will still be able to send friend requests in the future.
          </>
        }
        isOpen={status === "awaiting"}
      />
    </FriendsSidebarContext.Provider>
  );
}

export function useFriendsSidebar() {
  const context = useContext(FriendsSidebarContext);

  if (!context) {
    throw new Error(
      "useFriendsSidebar must be used within a FriendsSidebarProvider",
    );
  }

  return context;
}
