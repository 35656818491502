import { HYTOPIAClient } from "@hytopia.com/lib";
import { type NodeLocationType } from "@hytopia.com/lib/dist/play/matchmaking/NodeLocation";

export const hytopiaClient = new HYTOPIAClient(
  typeof window === "undefined"
    ? String(process.env.HYTOPIA_CLIENT_BASE_URL)
    : window.ENV.HYTOPIA_CLIENT_BASE_URL,
);

export async function joinServer(
  serverId: string,
  accessToken: string,
  region?: NodeLocationType,
) {
  let acceptedLocations: NodeLocationType[] = [];

  if (!region) {
    const nearbyLocations =
      await hytopiaClient.play.matchmaking.getNearbyLocations();

    if (nearbyLocations.error) {
      throw new Error(
        `Could not figure out nearby regions - ${nearbyLocations.error.code}`,
      );
    }

    acceptedLocations = nearbyLocations.map((entry) => entry.region);
  } else {
    acceptedLocations = [region];
  }

  const lobby =
    await hytopiaClient.play.matchmaking.lobbies.joinAvailableOrCreate({
      gameId: serverId,
      authToken: accessToken,
      acceptedLocations,
    });

  if (lobby.error) {
    throw new Error(`Could not join game - ${lobby.error.code}`);
  }

  const params = new URLSearchParams({
    join: lobby.url.replace("https://", ""),
    sessionToken: lobby.sessionToken,
    lobbyId: lobby.id,
  });

  return `https://play.hytopia.com?${params.toString()}`;
}
